<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card dense>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Auftragsbestätigung bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="12" xs="12">
                <v-card dense>
                  <v-card-text>
                    <v-form
                      name="confirmform"
                      @submit.prevent="save"
                      v-model="formValid"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <h2>
                              Auftragsdetails
                              <v-chip>Status: {{ confirmationStatus }}</v-chip>
                            </h2>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xs="12" sm="6">
                            <v-row>
                              <v-col cols="12" xs="12">
                                <ContactSelectbox
                                  dense
                                  color="purple"
                                  v-model="confirmation.customer"
                                  required
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-text-field
                                  label="Adresse"
                                  name="adresse"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="confirmation.customer.adresse"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-select
                                  label="Land"
                                  name="land"
                                  :items="countryList"
                                  item-text="nameDE"
                                  item-value="code"
                                  color="purple"
                                  :clearable="true"
                                  v-model="confirmation.customer.land"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" xs="12" sm="4">
                                <v-text-field
                                  label="PLZ"
                                  name="plz"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="confirmation.customer.plz"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" sm="8">
                                <v-text-field
                                  label="Ort"
                                  name="ort"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="confirmation.customer.ort"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-alert
                                  v-if="!mandant"
                                  dense
                                  border="left"
                                  type="warning"
                                >
                                  Bitte einen Mandanten auswählen!
                                </v-alert>
                                <v-text-field
                                  label="Auftragsbestätigungs-Nr"
                                  name="confirmnr"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="confirmation.confirmnr"
                                  required
                                  dense
                                  :rules="requiredRule"
                                  :disabled="!mandant"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <MenuDatePicker
                                  v-model="confirmation.date"
                                  name="date"
                                  label="Auftragsdatum"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-divider />
                        <h2>Produkte</h2>

                        <v-row dense>
                          <v-col cols="12" dense>
                            <draggable
                              v-model="confirmation.products"
                              v-bind="dragOptions"
                              class="products-list"
                              handle=".handle"
                            >
                              <transition-group>
                                <v-row
                                  dense
                                  v-for="(item, index) in confirmationProducts"
                                  :key="index"
                                >
                                  <v-col
                                    dense
                                    cols="12"
                                    sm="1"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-icon
                                      justify="center"
                                      class="handle"
                                      style="margin-right: 10px"
                                      >mdi-menu</v-icon
                                    >
                                  </v-col>
                                  <v-col dense cols="12" sm="10">
                                    <ProductFormItem
                                      dense
                                      :item="item"
                                      @fireUSTchange="calcMwst"
                                    />
                                  </v-col>
                                  <v-col dense cols="12" sm="1">
                                    <v-icon
                                      style="margin-right: 10px"
                                      @click="removeProduct(index)"
                                      >mdi-delete</v-icon
                                    >
                                  </v-col>
                                </v-row>
                              </transition-group>
                            </draggable>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn @click="addProduct">Zeile hinzufügen</v-btn>
                          </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                          <v-col cols="12" align="right">
                            <h4>
                              Gesamtsumme Netto: €
                              <span class="sum">{{
                                currencyFormat(totalNetto)
                              }}</span>
                            </h4>
                            <div
                              v-for="(item, index) in confirmation.mwstList"
                              :key="index"
                            >
                              <h4>
                                Mwst. {{ item.ust }}% €
                                <span class="sum">
                                  {{ currencyFormat(item.sum) }}
                                </span>
                              </h4>
                            </div>

                            <h4>
                              Gesamtsumme Brutto: €
                              <span class="sum">{{
                                currencyFormat(totalBrutto)
                              }}</span>
                            </h4>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-col cols="12" sm="3">
                      <v-btn
                        :to="{ path: '/confirmations' }"
                        color="error"
                        type="button"
                        name="cancel"
                        >Abbrechen</v-btn
                      >
                    </v-col>

                    <v-col cols="12" sm="9" class="text-right">
                      <v-btn
                        v-if="!!isEntwurf"
                        class="ma-2"
                        @click="saveDraft"
                        color="success"
                        name="save"
                        type="button"
                        outlined
                        :disabled="!formValid"
                        >Entwurf Speichern</v-btn
                      >

                      <v-btn
                        class="ma-2"
                        @click="saveCompleted"
                        color="success"
                        name="complete"
                        type="button"
                        :disabled="!formValid"
                        >Speichern</v-btn
                      >

                      <v-menu
                        v-if="!!isEntwurf || !!isAccepted || !!isCompleted"
                        dense
                        offset-y
                        align="right"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dense
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!formValid"
                            ><v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            @click="sendmail"
                            v-if="!!isEntwurf || !!isCompleted || !!isAccepted"
                            >Auftragsbestätigung senden</v-list-item
                          >
                          <v-list-item
                            @click="createPDF"
                            v-if="!!isEntwurf || !!isCompleted || !!isAccepted"
                            >PDF anzeigen</v-list-item
                          >
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MailPreviewDialog ref="mailDialog" />
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import DataService from "@/services/ConfirmationService.js";
import ContactSelectbox from "@/components/contacts/ContactSelectbox";
import ProductFormItem from "@/components/products/ProductFormItem";
import draggable from "vuedraggable";
import moment from "moment";
import itemStatus from "@/helpers/itemStatus.js";
import MailPreviewDialog from "@/components/MailPreviewDialog";
import MenuDatePicker from "@/components/MenuDatePicker";

export default {
  name: "confirmform",
  components: {
    ContactSelectbox,
    ProductFormItem,
    draggable,
    MailPreviewDialog,
    MenuDatePicker,
  },
  data: () => ({
    dateMenu: false,

    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid",
    ],
    formValid: true,
  }),

  methods: {
    sendmail() {
      this.$refs.mailDialog.setDataItem(this.confirmation);
      this.$refs.mailDialog.showDialog();
    },
    createPDF() {
      let pdfItem = this.confirmation;

      pdfItem.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
      pdfItem.mandantData = this.$store.getters[
        "mandant/getMandantenList"
      ].find((item) => item._id == this.confirmation.mandant);
      pdfItem.createTS = moment();
      DataService.makePdf(pdfItem)
        .then((response) => {
          let FileSaver = require("file-saver");
          let filename =
            "Auftragsbestätigung-" + this.confirmation.confirmnr + ".pdf";
          FileSaver.saveAs(
            new Blob([response.data], {
              type: "application/pdf;base64;",
            }),
            filename
          );
          console.log("success");
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message,
          });
        });
    },
    setNetto(price) {
      this.confirmation.totalNetto = price;
    },
    setBrutto(price) {
      this.confirmation.totalBrutto = price;
    },
    calcMwst() {
      let mwstList = [];

      for (let index in this.confirmationProducts) {
        let item = this.confirmationProducts[index];

        if (!item.ust || !item.totalSum) {
          continue;
        }
        let mwst =
          Number.parseFloat(item.totalSum) * (Number.parseInt(item.ust) / 100);

        let mwstItem = { ust: item.ust, sum: 0 };
        let mwstIndex = 0;
        let found = false;

        for (let i in mwstList) {
          if (mwstList[i].ust == item.ust) {
            mwstItem = mwstList[i];
            mwstIndex = i;
            found = true;
            break;
          }
        }

        mwstItem.sum = Number.parseFloat(
          Number(mwst + mwstItem.sum).toFixed(2)
        );

        if (found) {
          mwstList[mwstIndex] = mwstItem;
        } else {
          mwstList.push(mwstItem);
        }
      }
      this.confirmation.mwstList = mwstList;
    },
    addProduct() {
      this.$store.dispatch("confirmation/addProduct", {
        _id: null,
        productnr: null,
        name: "",
        description: "",
        productlink: "",
        quantity: 1,
        netto: 0,
        ust: 20,
        unit: "",
        totalSum: 0,
      });
      //this.confirmation.products.push();
    },
    removeProduct(index) {
      this.$store.dispatch("confirmation/removeProduct", index).then(() => {
        this.calcMwst();
      });
    },

    loadConfirmNr() {
      if (!this.confirmation.mandant || this.confirmation.mandant == "all") {
        this.confirmation.confirmnr == "";
        return false;
      } else {
        //this.formValid = true;
      }

      this.$store
        .dispatch("confirmation/getNextNr", this.confirmation.mandant)
        .then((result) => {
          if (result) {
            this.confirmation.lfdnr = result.lfdnr;
            this.confirmation.confirmnr = result.item;
          }
        });
    },

    acceptConfirmation() {
      this.confirmation.status = itemStatus.Accepted.code;
      this.save();
    },

    saveDraft() {
      this.confirmation.status = itemStatus.Draft.code;
      this.save();
    },
    saveCompleted() {
      this.confirmation.status = itemStatus.Completed.code;
      this.save();
    },

    save() {
      this.$store.commit("SET_APP_LOADING", true);
      this.confirmation.userKuerzel =
        this.$store.getters["user/getUser"].kuerzel;
      this.confirmation.mandantData = this.mandant;
      this.confirmation.createTS = moment();
      this.confirmation.templateID =
        this.confirmation.mandantData.pdfTemplates.confirmationTemplate;
      this.$store
        .dispatch("confirmation/saveConfirmation", this.confirmation)
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "confirmations" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Auftragsbestätigung gespeichert",
            text: "Auftragsbestätigung erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          console.log(error);
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
    fetchContact() {
      this.$store
        .dispatch("contact/fetchContact", this.confirmation.customer._id)
        .then(() => {})
        .catch(() => {});
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value ? value : 0);
    },
  },
  computed: {
    isEntwurf() {
      return this.confirmation.status == itemStatus.Draft.code;
    },
    isCompleted() {
      return this.confirmation.status == itemStatus.Completed.code;
    },
    isAccepted() {
      return this.confirmation.status == itemStatus.Accepted.code;
    },

    templateID() {
      const mandant = this.$store.getters["mandant/getMandantenList"].find(
        (item) => item._id == this.confirmation.mandant
      );

      if (mandant) {
        return mandant.pdfTemplates.confirmationTemplate;
      } else {
        return null;
      }
    },
    confirmationStatus() {
      let status = "Neu";

      for (const key of Object.keys(itemStatus)) {
        if (itemStatus[key].code == this.confirmation.status) {
          status = itemStatus[key].title;
        }
      }

      return status;
    },
    confirmationValidDate() {
      return moment().add("14", "days");
    },
    mandant() {
      return this.$store.getters["mandant/getWorkingMandantData"];
    },
    confirmationProducts() {
      const p = this.$store.getters["confirmation/getCurrent"];
      if (p) {
        return p.products;
      } else {
        return [];
      }
    },
    confirmation() {
      return this.$store.getters["confirmation/getCurrent"];
    },

    totalNetto() {
      let sum = 0;
      this.confirmationProducts.forEach((item) => {
        sum += Number.parseFloat(item.totalSum);
      });
      this.calcMwst();
      this.setNetto(sum);

      return Number(sum).toFixed(2);
    },

    totalBrutto() {
      let netto = this.totalNetto;
      let sum = netto;
      this.confirmation.mwstList.forEach((item) => {
        sum = Number.parseFloat(item.sum) + Number.parseFloat(sum);
      });
      this.setBrutto(sum);
      return Number(sum).toFixed(2);
    },

    dragOptions() {
      return {
        animation: 0,
        group: "",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    countryList() {
      return this.$store.getters["countries/getCountryList"];
    },
  },
  created() {
    let id = this.$route.params.id;
    if (!id) {
      this.$store.dispatch("confirmation/newConfirmation").then(() => {
        this.confirmation.date = moment().format("YYYY-MM-DD");
        this.confirmation.mandant =
          this.$store.getters["mandant/getWorkingMandant"];
        this.loadConfirmNr();
      });
    } else {
      this.$store
        .dispatch("confirmation/fetchConfirmation", {
          id: id,
          mandant: this.$store.getters["mandant/getWorkingMandant"],
        })
        .then(() => {
          this.fetchContact();
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    }
    eventBus.$on("mandantChanged", (data) => {
      if (data == "all") {
        this.confirmation.confirmnr = "";
      } else {
        this.confirmation.mandant = data;
        this.loadConfirmNr();
      }
    });
  },
  beforeDestroy() {
    this.$store.dispatch("confirmation/resetConfirmation");
  },
};
</script>
<style scoped>
.sum {
  margin-left: 20px;
}

.ghost {
  opacity: 0.5;
  background-color: #ccc;
}

.handle:hover {
  cursor: move;
}
</style>
